<template>
  <b-container :class="shoutboxClasses">
    <h1 class="shoutbox__title" v-html="data.title" />
    <h4 class="shoutbox__subtitle" v-html="data.subtitle" v-if="hasShoutDesc" />
    <slot name="custom-content"></slot>
    <slot name="buttons"></slot>
  </b-container>
</template>

<script>
import { isPresent } from '../utils/validators';

export default {
  name: 'ShoutBox',
  props: {
    data: Object,
    extraSpacing: Boolean,
  },
  computed: {
    hasShoutDesc() {
      return !!isPresent(this.data.subtitle);
    },
    shoutboxClasses() {
      if (this.extraSpacing) return 'shoutbox shoutbox--extra-spacing';
      return 'shoutbox';
    },
  },
};
</script>

<style scoped>

</style>
